import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import fundoUse from '../../files/use/fundo-use.svg';
// import FundoUse from '../../components/use/FundoUse';


function TitSubTitHowto() {
  const { t } = useTranslation('translation-ui');

  return (
    <>
     <h1 className="tit-use">{t('translation-ui:use.use-title', 'COMO USAR ☼')}</h1>
     <p className="sub-tit-use">{t('translation-ui:use.use-sub-title', 'Com DrySeat sinta-se seguro, sinta-se protegido. ☼')}</p>
    </>
    );
} 

function AddStringLocal(props) {

  //let res  = props.data.split('/dryd8/').join('http://localhost/dryd8/');
  //let res  = props.data.split('/sites/').join('http://local.dryd8.com/sites/');
  let res  = props.data.split('/dryd8/').join('https://dryseat.gomastudio.net/dryd8/');

  // console.log("res - como usar - ",res);
  return <div key={'usar-item-img'+props.type} className="img-how" dangerouslySetInnerHTML={ {__html: res} } />;

}



function SetUseText() {
    const { t, i18n } = useTranslation('use');


  const listItems = (
    <>
      <div key={'usar-item1'} className="col-12 col-sm-6 col-lg-3 base-use-item">
         <AddStringLocal  data={t('use:0.field_step_1_img', '☼')} type="1" />
         <div key={'usar-item-p1'} dangerouslySetInnerHTML={ {__html: t('use:0.field_step_1', '☼') } } />
      </div>
      <div key={'usar-item2'} className="col-12 col-sm-6 col-lg-3 base-use-item">
         <AddStringLocal  data={t('use:0.field_step_2img', '☼')} type="2" />
         <div key={'usar-item-p2'} dangerouslySetInnerHTML={ {__html: t('use:0.field_step_2', '☼') } } />

      </div>
      <div key={'usar-item3'} className="col-12 col-sm-6 col-lg-3 base-use-item">
         <AddStringLocal  data={t('use:0.field_step_2_img', '☼')} type="3" />
         <div key={'usar-item-p3'} dangerouslySetInnerHTML={ {__html: t('use:0.field_step_3', '☼') } } />

      </div>
      <div key={'usar-item4'} className="col-12 col-sm-6 col-lg-3 base-use-item">
         <AddStringLocal  data={t('use:0.field_step_4_img', '☼')} type="4" />
         <div key={'usar-item-p4'} dangerouslySetInnerHTML={ {__html: t('use:0.field_step_4', '☼') } } />
      </div>

    </>
  );
  return (
      <>{listItems}</>
  );

}

class Use extends React.Component {

  componentDidMount() {
  // console.log("Carregou o Use!!!!");
    
  }

  render() {
    return (
      <>
      <section className='use' id="sec3">
      {/* <FundoUse className='fundo-use' /> */}
      <img className="fundo-use" src={fundoUse} alt="Fundo Use" />

         <div className='container'>
          <div className="row">
            <div className="col-12">
              <TitSubTitHowto />
            </div>
          </div>
          <div className="row">

             <SetUseText />
          </div>
        </div>
      </section>
      </>
    );
  }
}

export default Use;