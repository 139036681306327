import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {useWindowDimension} from '../../components/utils.js';

import logo from '../../files/header-banner/logo-dry-seat.svg';
import bfly from '../../files/header-banner/borboleta.svg';

import { gsap } from "gsap";
import { ScrollToPlugin } from 'gsap/all';

gsap.registerPlugin(ScrollToPlugin);


function IconNew() {
    const { t } = useTranslation('translation-ui');
    return <p>{t('translation-ui:header.new-icon', 'New | Novo ☼')}</p>
} 

function BtContact() {
    const { t } = useTranslation('translation-ui');
    return <button className="contact-bt" onClick={(e) => window.location.href='mailto:help@dryseat.pt?subject=%2Binfo%20-%20DrySeat%20-%20website'} >{t('translation-ui:header.contact', 'Contate-nos ☼')}</button>
} 

function NavApp() {
    // const { t } = useTranslation('translation-ui');
    const { t, i18n } = useTranslation('translation-ui');

    const eleNav = t('translation-ui:header.nav', { returnObjects: true });  
    const [width] = useWindowDimension();

    const listItems = eleNav.map((ele, index) =>
        <li key={'nav-item'+index}>
           <a  className="main-nav-link" 
               //href={'#sec'+(index+1)} 
               onClick={(evt) => { 
                evt.preventDefault();

                console.log('i18n.language = '+i18n.language);
                // console.log('eleNav.length = '+eleNav.length);
                if (index === eleNav.length-1) {
                    if (i18n.language === 'pt') {
                        window.open('https://shop.ids-indesa.com/dryseat','_blank');
                    }else{
                        window.open('https://shop.ids-indesa.com/en/dryseat','_blank');
                    }
                }else{

                      if (width >= 650){
                      gsap.to(window, {duration: 1, scrollTo: {y: '#sec'+(index+1), offsetY:-20}});

                      }else {
                        let mainUl =  document.querySelector(".main-nav ul");
                        mainUl.classList.toggle('active');
                        let mainMenu =  document.querySelector(".menu");
                        mainMenu.classList.toggle('opened');
                      gsap.to(window, {duration: 1, scrollTo: {y: '#sec'+(index+1), offsetY:-20}, delay:0.8});

                        // revelar o scroll
                        let mainBody =  document.querySelector("body");
                        mainBody.classList.toggle('menu-mobile-active');
                        

                      }
              }
           
               }}
            >{ele}</a>
        </li>
    );
    return (
        <ul>{listItems}</ul>
    );
} 

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {open: true};
  }

  componentDidMount() {
    
  }

  tick() {
    this.setState({open: false});
  }


  openMenuMobile = (e) => {
    // console.log('this is:', this);
    // console.log('e is:', e.currentTarget );
    e.currentTarget.classList.toggle('opened');
    e.currentTarget.setAttribute('aria-expanded',  e.currentTarget.classList.contains('opened'));
   let mainUl =  document.querySelector(".main-nav ul");
   mainUl.classList.toggle('active');
   // esconder scroll
   let mainBody =  document.querySelector("body");
   mainBody.classList.toggle('menu-mobile-active');

  }

  render() {
    return (
      <header className='top-header' id="sec0">
        <div className='novo-icon'>
            <IconNew />
        </div>
        <div className='logo'>
            <img src={logo} alt="logo dry seat" />
        </div>
        <nav className="main-nav">
            <NavApp />
        </nav>
        <BtContact />

    <button className="menu" onClick={(e) => this.openMenuMobile(e)} aria-label="Main Menu">
      <svg width="100" height="100" viewBox="0 0 100 100">
        <path  className="line line1" strokeLinecap="round" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
        <path className="line line2" strokeLinecap="round" d="M 20,50 H 80" />
        <path className="line line3" strokeLinecap="round" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
      </svg>
    </button>

        <img className="ele-bf" src={bfly} alt="butter fly" />

      </header>
    );
  }
}

export default Header;