import React, { useRef, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import langIcon from '../../files/header-banner/lang.svg';



const ChangeLang = () => {

  //ir buscar os dados a locais diferentes
  // a json externo e a rest Api drupal
  const { i18n } = useTranslation('translation-ui');

  const btnEN = useRef();
  const btnPT = useRef();
  const btnES = useRef();
  const btnFR = useRef();
  const btnIT = useRef();
  const btnDE = useRef();
  const langBox = useRef();

  const allBtnLan = [btnEN,btnPT,btnES,btnFR,btnIT,btnDE];


  const changeLanguage = code => {
   // if (priVez === false) {
      meteVeu();
    // } else {
    //   priVez = false;
    // }
    // console.log("changeLanguage!!!!!");

    i18n.changeLanguage(code);
    disableButton(code);
    // document.documentElement.lang = i18n.language;
    document.documentElement.lang = code;

    window.history.pushState("","",`/${code}`);

    // globalThis.currentLanguage = code;
    
  };

  const meteVeu = () => {

    let tapa = document.querySelector(".tapa");
    tapa.style.display = "block";
    tapa.style.transform = "translate(0,0)";
    tapa.style.opacity = .7;
    setTimeout(function(){ 
        tapa.style.transform =  'translate(0,-100%)';
          tapa.style.display = "block";
    }, 2);

    setTimeout(function(){ 
        tapa.style.display = "none";
  }, 1500);

  }

  //console.log("i18n.language == "+i18n.language)

  const disableButton = code => {

    for (let index = 0; index < allBtnLan.length; index++) {
     allBtnLan[index].current.removeAttribute("disabled");
    }

   if (code === 'en') {
      btnEN.current.setAttribute("disabled", "disabled");
   } else if (code === 'pt') {
      btnPT.current.setAttribute("disabled", "disabled");
   } else if (code === 'es') {
      btnES.current.setAttribute("disabled", "disabled");
   } else if (code === 'fr') {
      btnFR.current.setAttribute("disabled", "disabled");
   } else if (code === 'it') {
      btnIT.current.setAttribute("disabled", "disabled");
   } else if (code === 'de') {
      btnDE.current.setAttribute("disabled", "disabled");
   }


  };

  function abrePorDefeitoLng(qualModal) {
    //confirmar a language para abrir
    if (qualModal.indexOf("en") !== -1 ) {
        setTimeout(function(){ changeLanguage('en'); }, 10);

    } else if(qualModal.indexOf("fr") !== -1) {
      setTimeout(function(){ changeLanguage('fr'); }, 10);

    } else if(qualModal.indexOf("es") !== -1) {
      setTimeout(function(){ changeLanguage('es'); }, 10);

    } else if(qualModal.indexOf("it") !== -1) {
      setTimeout(function(){ changeLanguage('it'); }, 10);

    } else if(qualModal.indexOf("de") !== -1) {
      setTimeout(function(){ changeLanguage('de'); }, 10);

    } 
  }


  useEffect(()=>{
    const finalPath = window.location.href;
    abrePorDefeitoLng(finalPath);
    disableButton(i18n.language);
    langBox.current.style.right = 0;

  },[]);

 // useEffect(() => {

   // const finalPath = window.location.href;
    //abrePorDefeitoLng(finalPath);


    //ChangeLang.style.right = -85;

    //let usrlang = navigator.language || navigator.userLanguage;
    //console.log("User's preferred language is: "+ usrlang);

    //let usrlangs = navigator.languages;
    //    console.log(usrlangs);


  //});

  

 
  return (
    <div ref={langBox} className="change-lang">
      <img className="change-icon" src={langIcon} alt="Change Language icon" />
      <button ref={btnPT} type="button" onClick={() => changeLanguage('pt')}>
        PT
      </button>
      <button ref={btnEN} type="button" onClick={() => changeLanguage('en')}>
        EN
      </button>
      <button ref={btnES} type="button" onClick={() => changeLanguage('es')}>
        ES
      </button>
      <button ref={btnFR} type="button" onClick={() => changeLanguage('fr')}>
        FR
      </button>
      <button ref={btnIT} type="button" onClick={() => changeLanguage('it')}>
        IT
      </button>
      <button ref={btnDE} type="button" onClick={() => changeLanguage('de')}>
        DE
      </button>
    </div>
  );
};


export default ChangeLang;