import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import bfly from '../../files/header-banner/borboleta.svg';
import ballElement from '../../files/beneficios/ball-element.svg';
import LinhasBanner from '../../components/slider/LinhasBanner';
import fundoProduto from '../../files/produto/fundo-produto.svg';



function TitSubTitBenefits() {
  const { t, i18n } = useTranslation('translation-ui');
  return (
    <>
     <img className="bfly" src={bfly} alt="Borbeleta Dryseat" />
     <h1 className="tit-benefits">{t('translation-ui:benefits.benefits-title', 'BENEFITS ☼')}</h1>
     <p className="sub-tit-benefits">{t('translation-ui:benefits.benefits-sub-title', 'Somos o seu protetor para assento sanitário ☼')}</p>
    </>
    );
} 

//modulo adicional para acrescentar a path as srcs da picture (img responsive drupal)
function AddStringLocal(props) {

  // let res  = props.data.split('/dryd8/').join('http://localhost/dryd8/');
  //let res  = props.data.split('/sites/').join('http://local.dryd8.com/sites/');
  let res  = props.data.split('/dryd8/').join('https://dryseat.gomastudio.net/dryd8/');


  if (props.type === "srcset") {
    return <div className="img-srcset" dangerouslySetInnerHTML={ {__html: res} } />;
  }else if (props.type === "fundo") {
    return <div className="img-fundo-beneficios" dangerouslySetInnerHTML={ {__html: res} } />;
  }else if (props.type === "ball") {
    return <AddBigBallElement linkImage={res} index= {props.index}/>;
  }else {
    return <div className="img-src" dangerouslySetInnerHTML={ {__html: res} } />;
  }
}

function AddBallElement(props) {
  let listItems;
  if (props.index === 0 || props.index === 3 || props.index === 5) {
     listItems = ( <div className="pai-anima-top">
                      <img className="ball-element" src={ballElement} alt="efeito decorativo" />
                   </div>
      ); 
  }else if (props.index === 1 || props.index === 6) { 
     listItems = (  <div className="pai-anima-right">
                      <img className="ball-element" src={ballElement} alt="efeito decorativo" />
                    </div>
                  );
  }else if (props.index === 2 || props.index === 4 ) { 
    listItems = (  <div className="pai-anima-right-top">
                     <img className="ball-element" src={ballElement} alt="efeito decorativo" />
                   </div>
                 );
 }else if (props.index === 7) { 
  listItems = (  <div className="pai-anima-left">
                   <img className="ball-element" src={ballElement} alt="efeito decorativo" />
                 </div>
               );
}
  return (
    <>{listItems}</>
  );
}

function AddBigBallElement(props) {
  let listItems;
  // class da anima da ball img-animated-ball
  if (props.index === 0 || props.index === 3 || props.index === 5) {
     listItems = <div className="iab1 img-animated-ball" dangerouslySetInnerHTML={ {__html: props.linkImage} } />; 
  }else if (props.index === 1 || props.index === 6) { 
    listItems = <div className="iab2 img-animated-ball" dangerouslySetInnerHTML={ {__html: props.linkImage} } />; 
  }else if (props.index === 2 || props.index === 4 ) { 
    listItems = <div className="iab3 img-animated-ball" dangerouslySetInnerHTML={ {__html: props.linkImage} } />; 
  }else if (props.index === 7) { 
    listItems = <div className="iab4 img-animated-ball" dangerouslySetInnerHTML={ {__html: props.linkImage} } />; 
  }
  return (
    <>{listItems}</>
  );
}

function BenefitsList() {

  const { t, i18n } = useTranslation('beneficios');

    // guardar os resultados num Array
    let rows = [];

    function countNumArray() {
       for (let i = 0; i < 500; i++) {
         const ele = i18n.t('beneficios:'+i+'', { returnObjects: true });
         if (ele != i) {
           rows.push(ele);
         }else{
           break;
         }
       }
    }
    countNumArray();


  const listItems = rows.map((ele, index) =>
      <div key={'beneficios-item'+index} className="col-10 offset-1 base-bene-item">
          <AddStringLocal  data={ele.field_image_beneficios} type="fundo" />
          <AddStringLocal  data={ele.field_image_beneficios} type="ball" index={index}/>
          <AddBallElement index={index} />

        <div className="row">
          <div className="col-sm-3">
            <AddStringLocal data={ele.field_icon} type="src" />
          </div>
          <div className="col-sm-9">
          <h2 className="tit-beneficios text-center text-sm-left">{ele.field_titulo_beneficios}</h2>
          <p className="sub-tit-beneficios text-center text-sm-left">{ele.field_sub_titulo_beneficios}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-3">
            <AddStringLocal data={ele.field_image_beneficios} type="srcset" />
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="texto-beneficios text-center text-sm-left" dangerouslySetInnerHTML={ {__html: ele.field_texto_beneficios } } />
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="texto-beneficios text-center text-sm-left" dangerouslySetInnerHTML={ {__html: ele.field_texto_beneficios_2 } } />
          </div>
        </div>
      </div>
  );
  return (
      <>{listItems}</>
  );
}

class Benefits extends React.Component {

  componentDidMount() {
  }

  render() {
    return (
      <>
      <section className='beneficios' id="sec2">
         <div className='container'>
          <div className="row">
            <div className="col-12">
              <TitSubTitBenefits />
            </div>
          </div>
          <div className="row">
             <LinhasBanner className="linhas-benefits l1" blendMode="normal" colorBalls="#00ABEC" />
             <LinhasBanner className="linhas-benefits l2" blendMode="normal" colorBalls="#00ABEC" />
             <LinhasBanner className="linhas-benefits l3" blendMode="normal" colorBalls="#00ABEC" />
            <img className="fundo-produto f1" src={fundoProduto} alt="efeito decorativo" />
            <img className="fundo-produto f2" src={fundoProduto} alt="efeito decorativo" />
             <BenefitsList />
          </div>
        </div>
        <svg width="0" height="0">
              <defs>
                <clipPath id="mask-ball" >
                    <path transform="translate(30,30)"  d="M361.035872,174.837649 C336.212338,429.825551 0.000501497684,429.615863 0,174.838045 C-0.000468064505,-58.8138147 383.678125,-57.7441367 361.035872,174.837649 Z"></path>
                </clipPath>
              </defs>
        </svg>
      </section>
      </>
    );
  }
}

export default Benefits;