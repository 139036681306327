import * as React from "react"

function LinhasBanner(props) {

  // Apanhar as 2 props custumizadss e passar o resto que já vinha para o SVG
  const { blendMode, colorBalls, ...rest } = props;

  return (
    <svg width={1324} height={921} viewBox="0 0 1324 921" {...rest}>
      <g
        style={{
          mixBlendMode: blendMode,
        }}
        transform="rotate(-25 1376.517 468.434)"
        stroke="#D6A229"
        fill="none"
        fillRule="evenodd"
      >
        <path opacity="0.7" d="M1477.724 0c-394.06 111.33-397.419 408.207-634.75 186.552-237.331-221.656-448.914-9.026-448.914-9.026S184.715 390.154 0 163.483" />
        <path opacity="0.7" d="M1477.724 30c-394.06 111.33-397.419 408.207-634.75 186.552-237.331-221.656-448.914-9.026-448.914-9.026S184.715 420.154 0 193.483" />
        <path opacity="0.7" d="M1477.724 62c-394.06 111.33-397.419 408.207-634.75 186.552-237.331-221.656-448.914-9.026-448.914-9.026S184.715 452.154 0 225.483" />
        <path opacity="0.7" d="M1477.724 94c-394.06 111.33-397.419 408.207-634.75 186.552-237.331-221.656-448.914-9.026-448.914-9.026S184.715 484.154 0 257.483" />
        <path opacity="0.7" d="M1477.724 125c-394.06 111.33-397.419 408.207-634.75 186.552-237.331-221.656-448.914-9.026-448.914-9.026S184.715 515.154 0 288.483" />
        <circle r={4} fill={colorBalls} stroke="none">
          <animateMotion
            path="M1477.72363,125 C1083.66364,236.329693 1080.30508,533.207289 842.97397,311.551533 C605.642856,89.8957775 394.059989,302.52552 394.059989,302.52552 C394.059989,302.52552 184.715454,515.154313 0,288.483261"
            begin="0s"
            dur="10s"
            repeatCount="indefinite"
            rotate="auto"
          />
        </circle>
        <circle r={4} fill={colorBalls} stroke="none">
          <animateMotion
            path="M1477.72363,125 C1083.66364,236.329693 1080.30508,533.207289 842.97397,311.551533 C605.642856,89.8957775 394.059989,302.52552 394.059989,302.52552 C394.059989,302.52552 184.715454,515.154313 0,288.483261"
            begin="0.2s"
            dur="10s"
            repeatCount="indefinite"
            rotate="auto"
          />
        </circle>
        <circle r={4} fill={colorBalls} stroke="none">
          <animateMotion
            path="M1477.72363,125 C1083.66364,236.329693 1080.30508,533.207289 842.97397,311.551533 C605.642856,89.8957775 394.059989,302.52552 394.059989,302.52552 C394.059989,302.52552 184.715454,515.154313 0,288.483261"
            begin="0.4s"
            dur="10s"
            repeatCount="indefinite"
            rotate="auto"
          />
        </circle>
      </g>
    </svg>
  )
}

export default LinhasBanner