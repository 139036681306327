import React, { useRef } from 'react';
import SvgComponent from '../../components/slider/SvgComponent';
import toiletImg from '../../files/header-banner/sanita.png';
import toiletImgRetina from '../../files/header-banner/sanita@2x.png';


class Toilet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {icons: []};

    // This binding is necessary to make `this` work in the callback

  }

  render() {
    return (
      <>
        <img className="toilet-img" src={toiletImg} srcSet={`${toiletImg} 1x, ${toiletImgRetina} 2x`} alt={'DrySeat!'} />
        {/* <object type="image/svg+xml" data={beneficiosSvg} className="toilet">
            Fallback here..
        </object> */}
        {/* <BeneficiosSvg data={this.alertme} /> */}
        <SvgComponent className="toilet" />
      </>
    );
  }
}

export default Toilet;