import React, { useState, useEffect } from 'react'; 
 
 /**
   * Functions Uteis para partilhar com outros componentes
   * import the specific functions that are needed. You don't have to import everything
   * import {loadPath, justAnAlert} from './path/to/utils.js/file'
  */

export const loadPath = (lng, namespace) => {

  let path = `/locales/${lng}/${namespace}.json`;

  //loadPath: '/locales/{{lng}}/{{ns}}.json',
  // loadPath: 'http://localhost/react/reactdrupal/reactdrupal/web/lang/{{lng}}/{{ns}}',

  /**
   * Add additional case stmts for new locale sub directories.
   * This allows for splitting up translation files into namespaces, namespace can
   * then be attached to a specific component or accessed through notation.
   */

   if (namespace[0] == 'translation-ui' ) {
      path = `/locales/${lng}/${namespace}.json`;
   } else if (namespace[0] == 'translation' ) {
      path = `/locales/${lng}/translation-ui.json`;
    } else{
        // path = `http://localhost/dryd8/web/lang/${lng}/${namespace}`;
        //path = `http://local.dryd8.com/lang/${lng}/${namespace}`;
        path = `https://dryseat.gomastudio.net/dryd8/web/lang/${lng}/${namespace}`;
    }
    // console.log('loadPath', path);

    return path;
    };




// 

 /**
   * For React devs who care performance seriously
   * function YourComponent() {
   *   const [width, height] = useWindowDimension();
   *   return <>Window width: {width}, Window height: {height}</>;
   * }
  */


export function useWindowDimension() {
  const [dimension, setDimension] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  useEffect(() => {
    const debouncedResizeHandler = debounce(() => {
    //   console.log('***** debounced resize'); // See the cool difference in console
      setDimension([window.innerWidth, window.innerHeight]);
    }, 100); // 100ms
    window.addEventListener('resize', debouncedResizeHandler);
    return () => window.removeEventListener('resize', debouncedResizeHandler);
  }, []); // Note this empty array. this effect should run only on mount and unmount
  return dimension;
}

function debounce(fn, ms) {
  let timer;
  return _ => {
    clearTimeout(timer);
    timer = setTimeout(_ => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}


/**
 * [trimHtml description] 
 * const trimText = trimHtml(dataText, { limit: 150 });
 * @param  {String} html
 * @param  {Object} options
 * @return {Object}
 */
export function trimHtml(html, options) {

  options = options || {};

  var limit = options.limit || 100,
      preserveTags = (typeof options.preserveTags !== 'undefined') ? options.preserveTags : true,
      wordBreak = (typeof options.wordBreak !== 'undefined') ? options.wordBreak : false,
      suffix = options.suffix || '...',
      moreLink = options.moreLink || '';

  var arr = html.replace(/</g, "\n<")
      .replace(/>/g, ">\n")
      .replace(/\n\n/g, "\n")
      .replace(/^\n/g, "")
      .replace(/\n$/g, "")
      .split("\n");

  var sum = 0,
      row, cut, add,
      tagMatch,
      tagName,
      tagStack = [],
      more = false;
  
  var rowCut;

  for (var i = 0; i < arr.length; i++) {

      row = arr[i];
      // count multiple spaces as one character
      rowCut = row.replace(/[ ]+/g, ' ');

      if (!row.length) {
          continue;
      }

      if (row[0] !== "<") {

          if (sum >= limit) {
              row = "";
          } else if ((sum + rowCut.length) >= limit) {

              cut = limit - sum;

              if (row[cut - 1] === ' ') {
                  while(cut){
                      cut -= 1;
                      if(row[cut - 1] !== ' '){
                          break;
                      }
                  }
              } else {

                  add = row.substring(cut).split('').indexOf(' ');

                  // break on halh of word
                  if(!wordBreak) {
                      if (add !== -1) {
                          cut += add;
                      } else {
                          cut = row.length;
                      }
                  }
              }

              row = row.substring(0, cut) + suffix;

              if (moreLink) {
                  row += '<a href="' + moreLink + '" style="display:inline">»</a>';
              }

              sum = limit;
              more = true;
          } else {
              sum += rowCut.length;
          }
      } else if (!preserveTags) {
          row = '';
      } else if (sum >= limit) {

          tagMatch = row.match(/[a-zA-Z]+/);
          tagName = tagMatch ? tagMatch[0] : '';

          if (tagName) {
              if (row.substring(0, 2) !== '</') {

                  tagStack.push(tagName);
                  row = '';
              } else {

                  while (tagStack[tagStack.length - 1] !== tagName && tagStack.length) {
                      tagStack.pop();
                  }

                  if (tagStack.length) {
                      row = '';
                  }

                  tagStack.pop();
              }
          } else {
              row = '';
          }
      }

      arr[i] = row;
  }

  return {
      html: arr.join("\n").replace(/\n/g, ""),
      more: more
  };
}

// if (typeof module !== 'undefined' && module.exports) {
//   module.exports = trimHtml;
// }
