import React, { useRef } from 'react';
import setaTop from '../../files/ui/seta-top.svg';

import { gsap } from "gsap";
import { ScrollToPlugin, ScrollTrigger, CSSRulePlugin } from 'gsap/all';
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, CSSRulePlugin);




class HelpNav extends React.Component {
  constructor(props) {
    super(props);
   // console.log("props.scrolYPOsi = "+props.scrolYPOsi);
    this.currentSection= 0;
    this.totalSec= 6;
   // this.scrolYPOsi = props.scrolYPOsi;

    ScrollTrigger.create({
      trigger: "#sec1",
      start: "1400px top",
      endTrigger: "#sec1",
      end: "1300px top",
      onLeave: () => { 
        this.currentSection =1;
        //alterar os valores da REGRA CSS - não quebra as transitions em CSS
        var rule = CSSRulePlugin.getRule(".help-nav"); //get the rule
        gsap.to(rule, {duration: 0, cssRule: {right: "-20px"}});

       
       
      },
      onEnterBack: () => { 
        this.currentSection = 0;
        var rule = CSSRulePlugin.getRule(".help-nav"); //get the rule
        gsap.to(rule, {duration: 0, cssRule: {right: "-100px"}});
      }
    });

    ScrollTrigger.create({
      trigger: "#sec2",
      start: "-300px top",
      endTrigger: "#sec2",
      end: "-300px top",
      onLeave: () => { 
        this.currentSection =2;
       // console.log("onLeave 2 = "+this.currentSection);

      },
      onEnterBack: () => { 
        this.currentSection =1;
       // console.log("onEnterBack 2 = "+this.currentSection);

      }
    });

    ScrollTrigger.create({
      trigger: "#sec3",
      start: "-300px top",
      endTrigger: "#sec3",
      end: "-300px top",
      onLeave: () => { 
        this.currentSection =3;
       // console.log("onLeave 3 = "+this.currentSection);

      },
      onEnterBack: () => { 
        this.currentSection =2;
       // console.log("onEnterBack 3 = "+this.currentSection);

      }
    });
    ScrollTrigger.create({
      trigger: "#sec4",
      start: "-300px top",
      endTrigger: "#sec4",
      end: "-300px top",
      onLeave: () => { 
        this.currentSection =4;
       // console.log("onLeave 4 = "+this.currentSection);

      },
      onEnterBack: () => { 
        this.currentSection =3;
       // console.log("onEnterBack 4 = "+this.currentSection);

      }
    });
    ScrollTrigger.create({
      trigger: "#sec5",
      start: "-300px top",
      endTrigger: "#sec5",
      end: "-300px top",
      onLeave: () => { 
        this.currentSection =5;
       // console.log("onLeave 5 = "+this.currentSection);

      },
      onEnterBack: () => { 
        this.currentSection =4;
       // console.log("onEnterBack 5 = "+this.currentSection);

      }
    });
  }

  componentDidMount() {
    //passar o ELE para o body para poder ficar :FIXED
    document.getElementById('main-body').appendChild(document.getElementById('help-nav'));
 
  }


  



  

  goToTop = () => {
    //console.log("props.scrolYPOsi = "+this.scrolYPOsi);

    this.currentSection = 0;
    gsap.to(window, {duration: 1.5, scrollTo: {y: 0}})
  };

  goToNext = () => {
   // console.log("----------------------------------------------------------------");

   // console.log("goToNext = this.currentSection  INIT = "+this.currentSection);

    //gsap.to(window, {duration: 0, scrollTo: {y: 0}})
    this.currentSection ++;
    if (this.currentSection === 7) {
      this.currentSection = 0;
    }
    let secToGo = this.currentSection;
    let finalURL = '#sec'+secToGo;

   // console.log("goToNext = this.currentSection  END = "+this.currentSection);

    
    if (this.currentSection === 0) {
      gsap.to(window, {duration: 0, scrollTo: {y: 0}});
    } else {
      // gsap.to(window, {duration: 0, scrollTo: {y: finalURL, offsetY:-40}});
      gsap.to(window, {duration: 1, scrollTo: {y: finalURL, offsetY:-20}});
    }

  };



  render() {
    return (
      <div id="help-nav" className='help-nav'>

        <div className="goto-top" onClick={() => this.goToTop()}>
          <img className="change-icon" src={setaTop} alt="Go to Top!" />
        </div>
        <div className="goto-next" onClick={() => this.goToNext()}>
          <img className="change-icon" src={setaTop} alt="Go to Next!" />
        </div>

      </div>
    );
  }
}

export default HelpNav;