import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import {loadPath} from '../../components/utils.js';
 
i18n
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // connect with React
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
 
    lng: 'pt',
    fallbackLng: 'pt',
    whitelist: ['en', 'pt', 'es', 'fr', 'it','de'],
 
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
 
    backend: {
      loadPath: loadPath,
     //loadPath: '/locales/{{lng}}/{{ns}}.json',
     //loadPath: 'http://localhost/react/reactdrupal/reactdrupal/web/lang/{{lng}}/{{ns}}',

      // allow cross domain requests
      crossDomain: true,


      // overrideMimeType sets request.overrideMimeType("application/json")
      overrideMimeType: false,
    },
  });
 
export default i18n;