//import React, { useRef } from 'react';
import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

import fundoDicas from '../../files/healt/fundo-dicas.svg';
import {trimHtml} from '../../components/utils.js';



import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';


// import Swiper core and required components
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

// install Swiper components
SwiperCore.use([Autoplay, Pagination]);

// declarar uma var GLOBAL para desenrascar o ID para MODAL
let finalID = 0;
let finalIDPath = null;
let codeLang = null;


function TitSubTitHealt() {
  const { t } = useTranslation('translation-ui');
  return (
    <>
     <h1 className="tit-healt">{t('translation-ui:healt.healt-title', 'Dicas de saúde e bem estar ☼')}</h1>
     <p className="sub-tit-healt">{t('translation-ui:healt.healt-sub-title', 'Descubra como melhorar a saúde e o bem-estar da sua família. ☼')}</p>
    </>
    );
} 

function CutStringHealt(props) {
  const trimText = trimHtml(props.dataText, { limit: 170 });
  return (
  <div className="text-item" dangerouslySetInnerHTML={ {__html:trimText.html } } />
  );
}

function SetImage(props) {
  //let res  = props.data.split('/dryd8/').join('http://localhost/dryd8/');
  //let res  = props.data.split('/sites/').join('http://local.dryd8.com/sites/');
  let res  = props.data.split('/dryd8/').join('https://dryseat.gomastudio.net/dryd8/');

  return (
    <div className="img-item" dangerouslySetInnerHTML={ {__html: res } } />
  );
}

function SliderDicas() {
  const { t, i18n } = useTranslation(['dicas','translation-ui']);
  const [modalShow, setModalShow] = React.useState(false);
  let rows = [];

  function countNumArray() {
     for (let i = 0; i < 500; i++) {
       const ele = i18n.t('dicas:'+i+'', { returnObjects: true });
       if (ele != i) {
         rows.push(ele);
       }else{
         break;
       }
     }
  }
  countNumArray();

  //Modal vars

  function actualizaDados(id) {
    finalID  = id.match(/\d+/g); 
  }

  const showModal = (event) => {
    // buscar o id para sicronizar como MODAL
    actualizaDados(event.currentTarget.id);
    setModalShow(true);
    //URL sync
    let numId = event.currentTarget.id.match(/\d+/g); 
    window.history.pushState("","",`${codeLang}/#healt/${numId}`);
  };

  const hideModal = () => {
    setModalShow(false);
    //window.history.go(-1);
    window.history.pushState("","",`/${codeLang}`);

  };

  const modalLoaded = () => {
    // console.log("--------- >>>>>>> finalID -- modalLoaded "+finalID); 
  };


  function abrePorDefeito(qualModal) {
          //confirmar a language para abrir por defeito
          if (qualModal.indexOf("en") !== -1 ) {
            codeLang = 'en';
          } else if(qualModal.indexOf("fr") !== -1) {
            codeLang = 'fr';
          } else if(qualModal.indexOf("es") !== -1) {
            codeLang = 'es';
          } else if(qualModal.indexOf("it") !== -1) {
            codeLang = 'it';
          } else if(qualModal.indexOf("de") !== -1) {
            codeLang = 'de';
          } else if(qualModal.indexOf("pt") !== -1) {
            codeLang = 'pt';
          } else {
            codeLang = 'pt';
          }

    //confirmar a path para ver se vale a pena carregar o modal
    if (qualModal.indexOf("healt") !== -1 && finalIDPath == null) {
      let myPath = window.location.href.split("/");
      finalIDPath = myPath[myPath.length-1];
      //setModalShow(true);
      setTimeout(function(){ finalID=finalIDPath; setModalShow(true); }, 2000);
    }
  }

  //esperar para que o componenete esteja carregado na memória para poder activar o modal
  // com o resultado da PATH URL / / /
  useEffect(() => {
    const finalPath = window.location.href;
    abrePorDefeito(finalPath);
  });



  const slides = rows.map((ele, i) =>
       <SwiperSlide className="healt-slide" key={`healt-slide-${i}`} tag="li" style={{ listStyle: 'none' }}>
            <SetImage data={ele.field_helt_img }/>
            {/* <div className="data-item" dangerouslySetInnerHTML={ {__html: ele.field_data } } /> */}
            <h2 className="tit-item" >{ele.field_titulo_healt}</h2>
            <CutStringHealt dataText={ele.field_texto_healty} />
            <button id={`modalitembt${i}`} className="button-item" variant="primary" onClick={showModal}>
              {t('translation-ui:healt.healt-button', 'SOW MORE ☼')}
            </button>
      </SwiperSlide>
  );

  return (
    <>
      <Swiper id="healt-swiper"
        spaceBetween={30}
        slidesPerView={3}
        //autoplay= { {delay: 5000} }
        loop={true}
        tag="div"
        wrapperTag="ul"
        pagination={{ clickable: true }}
        // onSwiper={(swiper) => console.log(swiper)}
        // onSlideChange={() => console.log('slide change')}
        breakpoints={{   0: { slidesPerView: 1, spaceBetween: 1}, 
                       577: { slidesPerView: 2, spaceBetween: 30}, 
                       768: { slidesPerView: 2, spaceBetween: 40},
                       993: { slidesPerView: 3, spaceBetween: 30},
                       1200: { slidesPerView: 3, spaceBetween: 60}
                    }}
      >
        {slides}
      </Swiper>
      <NewsModal
        show={modalShow}
        onHide={hideModal}
        onEntered={modalLoaded}
        key={`modal-slide-${1}`}
        id={`modal-slide-${1}`}
        data={rows}
        animation={false} 
      />
    </>
  );

}

function NewsModal(props) {


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        {/* <div className="data-item" dangerouslySetInnerHTML={ {__html: props.data[finalID].field_data } } /> */}
        <h2 className="tit-item" >{props.data[finalID].field_titulo_healt}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

      <SetImage data={props.data[finalID].field_helt_img }/>
      <div className="text-item" dangerouslySetInnerHTML={ {__html: props.data[finalID].field_texto_healty } } />

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>CLOSE</Button>
      </Modal.Footer>
    </Modal>
  );
}

class Healt extends React.Component {

  

  render() {
    return (
      <>
      <section className='healt' id="sec4">
      <div className='container'>
          <div className="row">
            <div className="col-12">
              <TitSubTitHealt />
            </div>
          </div>
          <div className="row">
            <img className="fundo-produto" src={fundoDicas} alt="efeito decorativo" />
            <div className="col-12 pai-dicas">
            {/* <StartModal /> */}
              <SliderDicas />
            </div>
          </div>
        </div>
        <svg width="0" height="0">
              <defs>
                <clipPath id="mask-heal-item" clipPathUnits="objectBoundingBox" transform="scale(0.003310 0.00334)">
        {/* <path d="M95.0195312,9.40625 C136.125922,7.49363599 255.624104,-10.6592044 281.15625,9.953125 C304.798032,29.0393472 293.488867,39.3622594 293.720703,92.0214844 C293.898777,132.465854 311.136719,251.376953 287.992495,280.605721 C264.848271,309.834489 145.970151,290.147831 130.272241,290.236674 C105.146003,290.378895 40.6973618,307.646598 18.471704,281.712117 C-1.05691796,258.925164 5.4980717,218.55289 5.26350565,165.278517 C5.18177866,146.716419 -7.53105957,18.0703392 11.0371094,10.1855469 C29.6052783,2.30075459 53.9131405,11.318864 95.0195312,9.40625 Z"></path> */}
        <path d="M95.0195312,9.40625 C136.125922,7.49363599 262.675145,-10.1712353 288.20729,10.4410942 C311.849073,29.5273163 298.086508,45.0317171 298.318344,97.6909421 C298.496418,138.135312 311.136719,251.376953 287.992495,280.605721 C264.848271,309.834489 145.970151,290.147831 130.272241,290.236674 C105.146003,290.378895 34.2603807,308.644744 12.0347229,282.710263 C-7.49389906,259.92331 4.95500841,205.848244 3.71611329,166.880159 C3.12626236,148.327047 -7.53105957,18.0703392 11.0371094,10.1855469 C29.6052783,2.30075459 53.9131405,11.318864 95.0195312,9.40625 Z"></path>
                 
                  {/* <path d="M94.3780976,11.7670735 C122.072739,11.6145788 259.381843,-14.2122387 284.08303,11.1416263 C305.549358,33.1762135 297.468851,28.9035462 297.707161,81.5627712 C297.890208,122.007141 309.986252,253.835379 292.740957,280.605721 C279.598989,301.007326 146.752591,290.147831 130.616311,290.236674 C104.788414,290.378895 38.5400228,307.646598 15.693706,281.712117 C-4.38025948,258.925164 2.35778051,218.55289 2.11666413,165.278517 C2.03265488,146.716419 -4.99665195,12.314271 7.03581412,12.2480735 L94.3780976,11.7670735 Z"></path> */}
                </clipPath>

              </defs>
        </svg>
      </section>
      </>
    );
  }
}

export default Healt;