// import React from 'react';
import React, { useEffect } from "react";

import { useTranslation } from 'react-i18next';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';

import pingoImg from '../../files/footer/pingo-footer.svg';
import logoInv from '../../files/footer/logo-inverso.svg';
import idsImg from '../../files/footer/ids_logo.svg';
import apoiosImg from '../../files/footer/barra_cofin_feder.svg';
import faceIcon from '../../files/footer/face.svg';
import instaIcon from '../../files/footer/insta.svg';
// import twitterIcon from '../../files/footer/twitter.svg';
import whatupIcon from '../../files/footer/whatup.svg';
import linkedinIcon from '../../files/footer/linkedin.svg';


import LinhasBanner from '../../components/slider/LinhasBanner';

import { gsap } from "gsap";
import { ScrollToPlugin } from 'gsap/all';
import { width } from "dom-helpers";

gsap.registerPlugin(ScrollToPlugin);


// declarar uma var GLOBAL para desenrascar o ID para MODAL
let finalIDPag = 0;
let finalIDPathPag = null;
let codeLang = null;


function NavFooter() {
  const { t } = useTranslation('translation-ui');
  const eleNav = t('translation-ui:header.nav', { returnObjects: true }); 
  const listItems = eleNav.map((ele, index) =>
      <li key={'nav-footer-item'+index}>
         <a className="footer-nav-link"  onClick={() => gsap.to(window, {duration: 0, scrollTo: {y: '#sec'+(index+1), offsetY:-40}}) }>{ele}</a>
      </li>
  );
  return (
      <ul className="footer-nav">{listItems}</ul>
  );
} 

function NavFooterSuporte(props) {
  const { t } = useTranslation('translation-ui');
  const eleNav = t('translation-ui:footer.menu2', { returnObjects: true }); 
  
  function handleClickM2(event) {
    event.preventDefault();
    //console.log('num = '+event.currentTarget.id);
    let finalIdMenu2 = event.currentTarget.id;

    if (finalIdMenu2 === "footer-nav0") {
      props.callFunc(finalIdMenu2);
    } else if (finalIdMenu2 === "footer-nav1") {
      window.location.href='mailto:sales@dryseat.pt?subject=Sales%20-%20DrySeat%20-%20website'
    } else {
      window.location.href='mailto:help@dryseat.pt?subject=Product%20-%20DrySeat%20-%20website';
    }
  }

  const listItems = eleNav.map((ele, index) =>
      <li key={'nav-footer-item-m2'+index}>
         <a className="footer-nav-link" id={`footer-nav${index}`}  onClick={handleClickM2} href="#null">{ele}</a>
      </li>
  );
  return (
      <ul className="footer-nav">{listItems}</ul>
  );
} 

function NavFooterContactos() {
  const { t } = useTranslation('translation-ui');
  const adress = t('translation-ui:footer.menu3', { returnObjects: true }); 
  return (
      <address className="footer-nav-adress">{adress}</address>
  );
} 

function DateAll(props) {   
  const currentDate = new Date();
  const currentYear =currentDate.getFullYear();
  return (
      <p className="cur-year">{currentYear} - {props.trans} </p>
  );    
}

function Policy(props) {
  const { t } = useTranslation('translation-ui'); 

  function handleClickSubFooterM(event) {
    event.preventDefault();
    let id = event.currentTarget.id;
     props.callFunc(id);
  }

  return (
      <p className="policy">
        <a className="poli" id={`sub-footer-nav1`}  onClick={handleClickSubFooterM} href="#null"> {t('translation-ui:sub-footer.policy', 'SOW MORE ☼')} </a>  
        |<a className="poli" id={`sub-footer-nav2`}  onClick={handleClickSubFooterM} href="#null"> {t('translation-ui:sub-footer.terms', 'SOW MORE ☼')} </a> 
      </p>
  );    
}

function Support(props) {
  const { t } = useTranslation('translation-ui'); 

  function handleClickSubFooterM(event) {
    event.preventDefault();
    let id = event.currentTarget.id;
     props.callFunc(id);
  }

  return (
    <a className="a-btn" id={`sub-footer-nav3`}  onClick={handleClickSubFooterM} href="#null">
      {/* <p className="co-fi">cofinanciado por:</p> */}
      <img className="apoios w-100" src={apoiosImg} alt="Apoios - Portugal 2020" style={{maxWidth: "400px"}} />
    </a>
  );    
}


function PagesModal(props) {
  //console.log(" >>>>>> props.data = "+props.data[0].title);
  //console.log(">>>>>>>>> finalIDPag -- "+finalIDPag);
  //console.log(">>>>>>>>> PagesModal -- props = ",props);
  return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          <h2 className="tit-item">{props.data[finalIDPag].title}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <div className="text-item" dangerouslySetInnerHTML={ {__html: props.data[finalIDPag].body } } />

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>CLOSE</Button>
        </Modal.Footer>
      </Modal>
  );
}


function TudoFooter() {
  const { t,i18n } = useTranslation(['paginas','translation-ui']);
  const [modalShowPag, setModalShowPag] = React.useState(false);
  const idsArray = ["common-questions-0","privacy-policy-1","terms-and-conditions-2","supports-3","intro-page-4"];

  let rows = [];
  function countNumArray() {
     for (let i = 0; i < 500; i++) {
       const ele = i18n.t('paginas:'+i+'', { returnObjects: true });
       if (ele != i) {
         rows.push(ele);
       }else{
         break;
       }
     }
  }
  countNumArray();

  // modal vars

  function actualizaDados(id) {
    finalIDPag  = id.match(/\d+/g);
    //console.log("actualizaDados ------ finalIDPag == "+finalIDPag);
  }

  const showModalPag = (idModalPag) => {
    // buscar o id para sicronizar como MODAL
    actualizaDados(idModalPag);
    setModalShowPag(true);
    //URL sync
    let numId = idModalPag.match(/\d+/g);
    let finalURL = idsArray[numId];

    const finalPath = window.location.href;
    confirmaLangCode(finalPath);
    window.history.pushState("","",`${codeLang}/#pages/${finalURL}`);
   // console.log("showModalPag ------ finalURL == "+finalURL);

  };


  const hideModalPag = () => {
    setModalShowPag(false);
    window.history.pushState("","",`/${codeLang}`);
  };

  function confirmaLangCode (qualModal){
    if (qualModal.indexOf("en") !== -1 ) {
      codeLang = 'en';
    } else if(qualModal.indexOf("fr") !== -1) {
      codeLang = 'fr';
    } else if(qualModal.indexOf("es") !== -1) {
      codeLang = 'es';
    } else if(qualModal.indexOf("it") !== -1) {
      codeLang = 'it';
    } else if(qualModal.indexOf("de") !== -1) {
      codeLang = 'de';
    } else if(qualModal.indexOf("pt") !== -1) {
      codeLang = 'pt';
    } else {
      codeLang = 'pt';
    }

  }

  function abrePorDefeitoPag(qualModal) {

 
      //confirmar a language para abrir por defeito
      confirmaLangCode(qualModal);
    
    //confirmar a path para ver se vale a pena carregar o modal
    if (qualModal.indexOf("pages") !== -1 && finalIDPathPag == null) {
      let myPath = window.location.href.split("/");
      finalIDPathPag = myPath[myPath.length-1];
      let numId = finalIDPathPag.match(/\d+/g);
      setTimeout(function(){ finalIDPag=numId; setModalShowPag(true); }, 3000);

    } else if(qualModal.indexOf("healt") !== -1) {
      // console.log("++++++ A PATH TEM HEALT - NAO ABRE O JOGO");
    }else {
      // console.log("++++++++ abrir por defeito o intro-game");

      //abrir por defeito o intro-game
      finalIDPathPag  = idsArray[4];
      finalIDPag=4;
      setTimeout(function(){ finalIDPag=4; showModalPag(finalIDPathPag); }, 3000);
    }
  }


  //esperar para que o componenete esteja carregado na memória para poder activar o modal
  // com o resultado da PATH URL / / /
  // para disparar apenas 1 vez o useEffect, devemos passar o array vazio no segundo argumento.

  useEffect(()=>{
    const finalPath = window.location.href;
    abrePorDefeitoPag(finalPath);
    //console.log('i fire once');
    setTimeout(colocaEventosGame, 3100);

  },[]);



  function colocaEventosGame() {


    if (finalIDPag == 4) {

      let simBt = document.querySelector(".sim-bt");
      let naoBt = document.querySelector(".nao-bt");
      let avancarBt = document.querySelector(".avancar-bt");

      

      avancarBt.addEventListener("click",hideModalPag);
      simBt.addEventListener("click",simClick);
      naoBt.addEventListener("click",simClick);
    }
  }

  function simClick(evt) {
    let simBt = document.querySelector(".sim-bt");
    let naoBt = document.querySelector(".nao-bt");

    let txtPergunta = document.querySelector('.txt-pergunta');
    let titResp = document.querySelector(".tit-resposta");
    let txtResp = document.querySelector(".txt-resposta");

    simBt.classList.add('d-none');
    naoBt.classList.add('d-none');

    txtPergunta.classList.add('d-none');
    titResp.classList.remove('d-none');
    txtResp.classList.remove('d-none');

    //fechar por defeito
    setTimeout(hideModalPag, 9000);

  }




  return (
    <>
      <footer className='main-footer' id="sec6">
            <div className="base-oval"></div>
            <LinhasBanner className="linhas-footer" blendMode="overlay" colorBalls="#00ABEC" />
          <img className="pingo-ele" src={pingoImg} alt="efeito decorativo Pingo" />

          <div className='container text-center text-md-left text-lg-left'>
            <img className="logo-inv" src={logoInv} alt="efeito decorativo Pingo" />
              <div className="row">
                <div className="col-md-12 col-lg-4 text-center text-md-left">
                    <h2 className="logo-desc">{t('translation-ui:footer.logo-desc', 'Somos a solução única para os momentos mais incómodos. ☼')}</h2>
                    <button id={`moreinfo`} className="button-item" variant="primary" onClick={(e) => window.location.href='mailto:help@dryseat.pt?subject=%2Binfo%20-%20DrySeat%20-%20website'}>
                      {t('translation-ui:footer.contact-btn', 'SOW MORE ☼')}
                    </button>
                </div>
                <div className="col-sm-3 col-lg-2 text-center text-sm-left" style={{paddingLeft:"30px"}}>
                  <p className="tit-menu-footer">
                    {t('translation-ui:footer.menu1-tit', 'MENU ☼')}
                  </p>
                  <NavFooter />
                 
                 </div>
                 <div className="col-sm-4 col-lg-3 text-center text-sm-left">
                    <p className="tit-menu-footer">
                      {t('translation-ui:footer.menu2-tit', 'SUPPORT ☼')}
                    </p>
                    <NavFooterSuporte callFunc={showModalPag}/>
                 </div>

                 <div className="col-sm-5 col-lg-3 text-center text-sm-left">
                    <p className="tit-menu-footer">
                      {t('translation-ui:footer.menu3-tit', 'CONTACT ☼')}
                    </p>
                    <NavFooterContactos />
                 </div>
              </div>
              <div className="row links">
                <div className="col-md-6 link1 justify-content-center justify-content-md-start">
                {/* componente que passa como uma prop, uma function externa - fica dispoivel dentro dele */}
                  <Support callFunc={showModalPag}/>
                </div>
                <div className="col-md-6 link2 justify-content-center justify-content-md-end">
                  <a href="https://www.facebook.com/Dryseat-102449661345997/" target="_blanc">
                    <img className="apoios" src={faceIcon} alt="Facebook icon" />
                  </a>
                  <a href="https://www.instagram.com/dryseatcleansafe/" target="_blanc">
                    <img className="apoios" src={instaIcon} alt="Instagram icon" />
                  </a>
                  <a href="https://www.linkedin.com/company/dryseat" target="_blanc">
                    <img className="apoios" src={linkedinIcon} alt="linkedin icon" />
                  </a>
                  {/* <img className="apoios" src={twitterIcon} alt="Twitter icon" /> */}
                  <a href="https://wa.me/351917232222" target="_blanc">
                    <img className="apoios" src={whatupIcon} alt="Whatup icon" />
                  </a>

                </div>
              </div>
          </div>
          <PagesModal
            show={modalShowPag}
            onHide={hideModalPag}
            key={`modal-pages-${1}`}
            id={`modal-pages-${1}`}
            data={rows}
            animation={false}
            data-uniid={finalIDPag}
          />
          <div className="sub-footer">
            <div className='container'>
             <div className="row">
              <div className="col-md-8 flex-date justify-content-center justify-content-md-start">
                <DateAll trans={t('translation-ui:sub-footer.date', 'SOW MORE ☼')} />
                <a className="a-btn" id={`sub-footer-nav4`}  href="https://ids-indesa.com" target="_blank">
                  <img className="apoios" src={idsImg} alt="Apoios - IDS- indesa" style={{height: "73px",
                  position: 'relative',
                  bottom: '7px',
                  left: '5px',
                  }} />
                </a>
                <a className="mb-4 mb-lg-0" href="https://www.ids-indesa.com/sites/default/files/2021-11/IDS_9001_IPAC_PT_2021.pdf" target="_blank" style={{width: "150px"}}>
                  <img src="https://ids-indesa.com/sites/default/files/2021-11/certificacao-cor.svg" alt="Iso 9001:2015" style={{maxWidth: "150px"}}/>
                </a>
              </div>
              <div className="col-md-4 flex-poli justify-content-center justify-content-md-end">
                <Policy callFunc={showModalPag}/>
              </div>
             </div>
            </div>
          </div>
        </footer>
      </>
    );
} 


class Footer extends React.Component {
  render() {
    return ( <TudoFooter /> );
  }
}

export default Footer;