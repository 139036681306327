import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Toilet from '../../components/slider/Toilet';
import ChangeLang from '../../components/slider/ChangeLang';
import LinhasBanner from '../../components/slider/LinhasBanner';
import {useWindowDimension} from '../../components/utils.js';

import fundoNuvens from '../../files/header-banner/fundo_nuvens.jpg';
import happyDestaque from '../../files/header-banner/happy.png';
import iconCerto from '../../files/header-banner/certo.svg';

import caixaPopUp from '../../files/header-banner/caixa-pop-up.svg';


// import Swiper core and required components
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';

// install Swiper components
SwiperCore.use([Autoplay]);




function BannerMask() {
  //Componente para tornar a mascara SVG responsive com scale
  const [width, height] = useWindowDimension();

  //console.clear();
  //console.log("+++++++ width viewport = "+width);

  let transScale = "scale(0.00075 0.00180)";

  if (width >= 1760) {
    //transScale = "scale(0.00075 0.00100)";
  } else if (width >= 1638) {
    //transScale = "scale(0.00075 0.00105)";
  } else if (width >= 1500) {
    //transScale = "scale(0.00075 0.00115)";
  } else if (width >= 1400) {
    //transScale = "scale(0.00075 0.00125)";
  } else if (width >= 1318) {
    //transScale = "scale(0.00075 0.00135)";
  } else if (width >= 1218) {
    //transScale = "scale(0.00075 0.00145)";
  }else if (width >= 1140) {
    //transScale = "scale(0.00075 0.00155)";
  } else if (width >= 1077) {
   // transScale = "scale(0.00075 0.00175)";
  }

  return <>
                 <clipPath id="banner-mask" clipPathUnits="objectBoundingBox" transform={transScale}>
                        <path d="M553.928308,17.7780018 C715.771393,17.7780018 1081.8443,-22.2225023 1225.51968,17.7780018 C1314.31726,42.5011657 1324.30947,78.8089886 1324.01389,121.203733 C1323.89385,138.420909 1324.01389,153.75501 1324.01389,177.622731 C1324.01389,241.09225 1350.37936,530.824229 1267.495,530.824229 C1158.28599,530.824229 851.379474,530.824229 757.081578,530.824229 C606.148032,530.824229 218.310907,571.719714 85.4902089,530.824229 C-31.2130369,494.89199 9.22973419,431.595387 9.22973419,347.991654 C9.22973419,318.862031 -26.8005373,17.7780018 43.5148913,17.7780018 L553.928308,17.7780018 Z"></path>
                </clipPath>
  </>;
}




function SliderVantagens() {
  const { t, i18n } = useTranslation('banner-vantagens');

  let rows = [];

  function countNumArray() {

     for (let i = 0; i < 500; i++) {
       const ele = i18n.t('banner-vantagens:'+i+'', { returnObjects: true });
       //console.log("ele.body => "+ele.field_sub_titulo);
       if (ele != i) {
         //console.log("ele.body => "+ele.field_sub_titulo);
         rows.push(ele);
       }else{
         break;
       }
     }
  }
 
  // APanhar os elementos da traducao, colocar num array os resultados
  // A var rows fica com o Array com os resultados para podermos apanhar com array.map no return
  countNumArray();

  const vantagens3em3 = [];
  vantagens3em3.group1 = [];
  vantagens3em3.group2 = [];
  vantagens3em3.group3 = [];


  function addVantagensGroup() {
    let quantas = rows.length;

    for (let index = 0; index < quantas; index++) {
      const element = rows[index];
      if (index<3) {
        vantagens3em3.group1.push(element);
      }else if (index>2 && index<6){
        vantagens3em3.group2.push(element);
      }else if (index>5 && index<9){
        vantagens3em3.group3.push(element);
      }
    }
  }
  addVantagensGroup();



  const slides = [];
  for (let i = 0; i < 3; i += 1) {
    slides.push(
      <SwiperSlide className="group-slide" key={`slide-${i}`} tag="li" style={{ listStyle: 'none' }}>
          { vantagens3em3['group'+(i+1)].map((data, index) => {
                return (
                  
                  <p className="van-item" key={`sub-slides-${index}`}>
                    <img
                      src={iconCerto}
                      alt={'icon vantagens'}
                    />
                    {data.field_sub_titulo}
                  </p>
                 
              );
            })}
        
      </SwiperSlide>
    );
  }

  return (
    <>
      <Swiper id="main-swiper"
        spaceBetween={0}
        slidesPerView={1}
        autoplay= { {delay: 5000} }
        loop={true}
        tag="div"
        wrapperTag="ul"
        // onSwiper={(swiper) => console.log(swiper)}
        // onSlideChange={() => console.log('slide change')}
      >
        {slides}
      </Swiper>
    </>
  );

}


function TitSubTit() {
  const { t, i18n } = useTranslation('banner');
  //const finalString = i18n.t('banner:0.field_titulo');

  return (
    <>
      <p key={"tit-1"}className="slider-tit">{t('banner:0.field_titulo', 'Somos o seu  protetor para assento sanitário☼')}</p>
      <p key={"sub-tit-1"} className="slider-sub-tit">{t('banner:0.field_sub_titulo', 'Produzido com material 100% biodegradável☼')}</p>
    </>
  )
  
} 

class Slider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {open: true};
  }

  componentDidMount() {

  }

  render() {
    return (
      <section className='slider'>
        <div className='slider-wrapper'>
         <img className="fundo-nuvens" src={fundoNuvens} alt="nuvens" />
         <LinhasBanner className="linhas-banner" colorBalls={"#fff"} />
         <div className='container'>
          <div className="row tudo-row-slider">
              <div className="col1-ajust-slider col-md-6 col-lg-5 col-xl-5 offset-xl-0 d-flex flex-row flex-wrap align-content-center justify-content-center justify-content-md-start">
                <TitSubTit />
                <SliderVantagens />
              </div>
              <div className="col-md-1 col-lg-2 col-xl-2 col-vazia">
                  
              </div>
              <div className="col-md-5 col-lg-5 col-xl-5 pai-toilet">
                <Toilet />
              </div>
          </div>
         </div>
         <img className="happy" src={happyDestaque} alt="happy people" data-speed="0.2" data-direction="down"/>
         
        </div>
        <div className="pop-up-global" id="caixa-base-pop" style={{ position: 'fixed',top: '0', left:'0', pointerEvents:'none' }}>
          {/* <img src={caixaPopUp} className="caixa-pop-up" id="caixa-base-pop" style={{ position: 'fixed',top: '0', left:'0', pointerEvents:'none' }} alt="caixa-pop"/> */}
          <img src={caixaPopUp} className="caixa-pop-up"  alt="caixa-pop"/>
          <p className="des-pop-up">Ideal para ser usado por toda a família</p>
        </div>
            <svg width="0" height="0" preserveAspectRatio="none">
              <defs>
              <BannerMask />
                {/* <clipPath id="banner-mask" clipPathUnits="objectBoundingBox" transform="scale(0.00075 0.00175)">
                        <path d="M553.928308,17.7780018 C715.771393,17.7780018 1081.8443,-22.2225023 1225.51968,17.7780018 C1314.31726,42.5011657 1324.30947,78.8089886 1324.01389,121.203733 C1323.89385,138.420909 1324.01389,153.75501 1324.01389,177.622731 C1324.01389,241.09225 1350.37936,530.824229 1267.495,530.824229 C1158.28599,530.824229 851.379474,530.824229 757.081578,530.824229 C606.148032,530.824229 218.310907,571.719714 85.4902089,530.824229 C-31.2130369,494.89199 9.22973419,431.595387 9.22973419,347.991654 C9.22973419,318.862031 -26.8005373,17.7780018 43.5148913,17.7780018 L553.928308,17.7780018 Z"></path>
                </clipPath> */}
              </defs>
            </svg>
            <ChangeLang  />
      </section>
    );
  }
}

export default Slider;