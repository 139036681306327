import React, { useRef, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import Product1Global from '../../components/product/Product1Global';
import Product2Global from '../../components/product/Product2Global';



import fundoProduto from '../../files/produto/fundo-produto.svg';
import frenteEmbalagem from '../../files/produto/frente-embalagem.png';
import frenteEmbalagem2x from '../../files/produto/frente-embalagem@2x.png';
import versoEmbalagem from '../../files/produto/verso-embalagem.png';
import versoEmbalagem2x from '../../files/produto/verso-embalagem@2x.png';

import fundoPopup from '../../files/produto/fundo-popup.svg';






function TitSubTitProduct() {
    const { t, i18n } = useTranslation('translation-ui');
    return (
      <>
       <h1 className="tit-product">{t('translation-ui:product.product-title', 'PRODUTO ☼')}</h1>
       <p className="sub-tit-product">{t('translation-ui:product.product-sub-title', 'Somos o seu protetor para assento sanitário ☼')}</p>
      </>
      );
} 


function CreateTollTips() {
  const { t, i18n } = useTranslation('translation-ui');
  const eleNav = t('translation-ui:product.product-img1-leg', { returnObjects: true }); 
  const toolItems = eleNav.map((ele, index) =>

      <div className="tool-tip-product" key={'tool-tip-product'+index} id={`tooltip-p`+(index)}>
        <img className="fundo-fx-pro" src={fundoPopup} alt="fx" />
        <svg width="64px" height="56px" viewBox="0 0 64 56" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <defs></defs>
          <g id="Welcome" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeDasharray="3,3,3">
              <g id="Desktop-Copy-5" transform="translate(-311.000000, -960.000000)" stroke="#005E81">
                  <g id="product1" transform="translate(172.000000, 940.000000)">
                      <g id="mockup-1" transform="translate(132.000000, 0.000000)">
                          <path d="M70.796875,20.9199219 C25.8088556,20.9199219 20.8266966,35.8018981 7.86580436,77.2367385" id="join-line"></path>
                      </g>
                  </g>
              </g>
          </g>
        </svg>
        <div className="leg-product-group">
          <p className="leg-product">{ele}</p>
        </div>
      </div>

  );
  return (
      <>{toolItems}</>
  );
} 

function CreateTollTips2() {
  const { t, i18n } = useTranslation('translation-ui');
  const eleNav = t('translation-ui:product.product-img2-leg', { returnObjects: true }); 
  const toolItems = eleNav.map((ele, index) =>

      <div className="tool-tip-product" key={'tool-tip-product2'+index} id={`tooltip-p2`+(index)}>
        <img className="fundo-fx-pro" src={fundoPopup} alt="fx" />
        <svg className="linhas-connect" width="64px" height="56px" viewBox="0 0 64 56" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <defs></defs>
          <g id="Welcome" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeDasharray="3,3,3">
              <g id="Desktop-Copy-5" transform="translate(-311.000000, -960.000000)" stroke="#005E81">
                  <g id="product1" transform="translate(172.000000, 940.000000)">
                      <g id="mockup-1" transform="translate(132.000000, 0.000000)">
                          <path d="M70.796875,20.9199219 C25.8088556,20.9199219 20.8266966,35.8018981 7.86580436,77.2367385" id="join-line"></path>
                      </g>
                  </g>
              </g>
          </g>
        </svg>
        <div className="leg-product-group">
          <p className="leg-product">{ele}</p>
        </div>
      </div>

  );
  return (
      <>{toolItems}</>
  );
} 


function TitDescProdut1() {
  const { t } = useTranslation('produto');
  return (
    <>
     <h2 key={'title-product-1'} className="title-product">{t('produto:0.field_titulo', 'VANTAGENS DO DRY SEAT ☼')}</h2>
     <div key={'title-desc-1'} className="desc-product" dangerouslySetInnerHTML={ {__html: t('produto:0.field_descricao_1', 'Somos o seu protetor para assento sanitário ☼')}} />
    </>
    );
} 

function TitDescProdut2() {
  const { t } = useTranslation('produto');
  return (
    <>
     <h2 key={'title-product-2'} className="title-product">{t('produto:0.field_titulo_2', 'VANTAGENS DO DRY SEAT ☼')}</h2>
     <div key={'title-desc-2'} className="desc-product" dangerouslySetInnerHTML={ {__html: t('produto:0.field_descricao_2', 'Somos o seu protetor para assento sanitário ☼')}} />
    </>
    );
} 

function TitDescProdut3() {
  const { t } = useTranslation('produto');
  return (
    <>
     <h2 key={'title-product-3'} className="title-product">{t('produto:0.field_titulo_3', 'VANTAGENS DO DRY SEAT ☼')}</h2>
     <div key={'title-desc-3'} className="desc-product" dangerouslySetInnerHTML={ {__html: t('produto:0.field_descricao_3', 'Somos o seu protetor para assento sanitário ☼')}} />
    </>
    );
} 

function PackPrototipo() {

  const scene3d = document.querySelector(".card3d");
  let viraScene3d = false;


  function init3dTouch(evt) {
    // evt.preventDefault();
    if (viraScene3d === false) {
      scene3d.style.transform = "rotateY(180deg)";
      viraScene3d = true;
    }else {
      scene3d.style.transform = "rotateY(0)";
      viraScene3d = false;
    }
  }


  return (
    <>
         <div className="scene" onTouchStart={init3dTouch} >
          <div className="card3d">
            <div className="face front">      
              <img className="pack-img" src={frenteEmbalagem} srcSet={`${frenteEmbalagem} 1x, ${frenteEmbalagem2x} 2x`} alt={'DrySeat!'} />
            </div>
            <div className="face back">
              <img className="pack-img" src={versoEmbalagem} srcSet={`${versoEmbalagem} 1x, ${versoEmbalagem2x} 2x`} alt={'DrySeat!'} />
            </div>
          </div>
        </div>
     
    </>
    );
}



function VideoProdut() {

  const { t, i18n } = useTranslation('produto');

  const code = i18n.language;
  let finalLinkVideo = null;
  let idVideo = null;
  if (code === 'en') {
    finalLinkVideo = 'https://www.youtube-nocookie.com/embed/JCPtgvh7LI0';
    idVideo = 'JCPtgvh7LI0';
 } else if (code === 'pt') {
  finalLinkVideo = 'https://www.youtube-nocookie.com/embed/oQlfXAM8oJk';
  idVideo = 'oQlfXAM8oJk';
 } else if (code === 'es') {
  finalLinkVideo = 'https://www.youtube-nocookie.com/embed/QhJ2npihOm4';
  idVideo = 'QhJ2npihOm4';
 } else if (code === 'fr') {
  finalLinkVideo = 'https://www.youtube-nocookie.com/embed/s9d8GYZdgpw';
  idVideo = 's9d8GYZdgpw';
 } else if (code === 'it') {
  finalLinkVideo = 'https://www.youtube-nocookie.com/embed/0HhrDML5MnI';
  idVideo = '0HhrDML5MnI';
 } else if (code === 'de') {
  finalLinkVideo = 'https://www.youtube-nocookie.com/embed/gx4EMfPke6E';
  idVideo = 'gx4EMfPke6E';
 }

 //para mute &mute=1
 //src={finalLinkVideo+'?enablejsapi=1&version=3&playerapiid=ytplayer&showinfo=0&controls=0&rel=0&loop=1&playlist='+idVideo} 
 //

  return (
    <>
     <iframe 
             className="video yt_player_iframe"
             src={finalLinkVideo+'?enablejsapi=1&version=3&playerapiid=ytplayer&mute=1&showinfo=0&controls=1&rel=0&loop=1&playlist='+idVideo} 
             frameBorder="0" 
             allowscriptaccess="always" 
             allow="accelerometer; autoplay; clipboard-write; modestbranding; encrypted-media; gyroscope; picture-in-picture; web-share" 
             allowFullScreen>

      </iframe>
     
    </>
    );
} 





class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {open: true};
  }

  componentDidMount() {

    setTimeout(()=>{

      const videoContainer = document.querySelector('.video-container');
      if (!!window.IntersectionObserver) {
        let video = document.querySelector(".video");
        console.log('video--- ',video);
        console.log('videoContainer--- ',videoContainer);
        let observer = new IntersectionObserver(
          (entries, observer) => {
            entries.forEach((entry) => {
              if (entry.intersectionRatio != 1 ) {
              //  console.log('video stop!');
                video.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
              } else {
                //console.log('video play!');
                video.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
      
              }
            });
          },
          { threshold: 1 }
        );
         observer.observe(videoContainer);
        } 

    }, 2100);


  }


  render() {
    return (
      <>
      <section className='produto' id="sec1">

         <img className="fundo-produto" src={fundoProduto} alt="efeito decorativo"  />
         <div className='container'>
          <div className="row">
              <div className="col-12">
                <TitSubTitProduct />
              </div>
          </div>
          <div className="row mb-5 pb-4">
              <div className="col-md-6">
                <Product1Global className="product1-prototipo parallax" data-speed="0.1" data-direction="down"/>
              </div>
              <div className="col-md-6 desc-icon">
                <TitDescProdut1 />
              </div>
          </div>
          <div className="row">
              <div className="col-md-6 desc-icon">
                <TitDescProdut2 />
              </div>
              <div className="col-md-6">
                <Product2Global className="product2-prototipo parallax" data-speed="0.1" data-direction="down"/>
              </div>
          </div>
          <div className="row">
              <div className="col-md-6">
                <PackPrototipo className="pack-prototipo" />
              </div>
              <div className="col-md-6 desc-icon parallax" data-speed="0.05" data-direction="down">
                <TitDescProdut3/>
              </div>
              <div className="col-12 col-sm-8  offset-sm-2 my-5 pt-5">
                <div className="video-container">
                  <VideoProdut/>
                </div>
              </div>
          </div>
        </div>
      </section>
      <CreateTollTips />
      <CreateTollTips2 />
      </>
    );
  }
}

export default Product;