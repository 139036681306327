import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import aspasDir from '../../files/testemunhos/aspas-dir.svg';
import aspasEsq from '../../files/testemunhos/aspas-esq.svg';
import ballElement from '../../files/beneficios/ball-element.svg';

import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

// install Swiper components
SwiperCore.use([Autoplay, Pagination]);




function TitSubTitTestimonials() {
  const { t } = useTranslation('translation-ui');
  return (
    <>
     <h1 className="tit-testimonials">{t('translation-ui:testimonials.testimonials-title', 'Dicas de saúde e bem estar ☼')}</h1>
     <p className="sub-tit-testimonials">{t('translation-ui:testimonials.testimonials-sub-title', 'Descubra como melhorar a saúde e o bem-estar da sua família. ☼')}</p>
    </>
    );
} 


function SliderTestimonials() {
  const { t, i18n } = useTranslation(['testemunhos']);
  let rows = [];

  function countNumArray() {
     for (let i = 0; i < 500; i++) {
       const ele = i18n.t('testemunhos:'+i+'', { returnObjects: true });
       if (ele != i) {
         rows.push(ele);
       }else{
         break;
       }
     }
  }
  countNumArray();

  const slides = rows.map((ele, i) =>
       <SwiperSlide className="testimonials-slide" key={`testimonials-slide-${i}`} tag="li" style={{ listStyle: 'none' }}>
          <div className="slide-wrapper">
            <img className="ball-ele" src={ballElement} alt="efeito decorativo" />
            <blockquote className="text-item" dangerouslySetInnerHTML={ {__html: ele.field_descricao } } />
            <p className="tit-item" >{ele.field_nome}</p>
            <img className="aspas-esq" src={aspasDir} alt="efeito decorativo" />
            <img className="aspas-dir" src={aspasEsq} alt="efeito decorativo" />
          </div>
      </SwiperSlide>
  );

  return (
    <>
      <Swiper id="testimonials-swiper"
        spaceBetween={30}
        slidesPerView={1}
        autoplay= { {delay: 10000} }
        loop={true}
        tag="div"
        wrapperTag="ul"
        pagination={{ clickable: true }}
        // onSwiper={(swiper) => console.log(swiper)}
        // onSlideChange={() => console.log('slide change')}
      >
        {slides}
      </Swiper>
    </>
  );

}

class Testimonials extends React.Component {

  render() {
    return (
      <>
      <section className='testimonials' id="sec5">
      <div className='container'>
          <div className="row">
            <div className="col-12">
              <TitSubTitTestimonials />
            </div>
          </div>
        </div>
          <div className="row">
            <div className="col-12">
              <SliderTestimonials />
            </div>
          </div>

      </section>
      </>
    );
  }
}

export default Testimonials;