import React from 'react';
import Header from '../header/Header';
import Slider from '../slider/Slider';
import Product from '../product/Product';
import Benefits from '../benefits/Benefits';
import Use from '../use/Use';
import Healt from '../healt/Healt';
import Testimonials from '../testimonials/Testimonials';
import Footer from '../footer/Footer';
import HelpNav from '../helpnav/HelpNav';


// import { Helmet } from 'react-helmet';


//tweenMax
import { TweenLite } from "gsap/all";
// import AOS from 'aos';
// import 'aos/dist/aos.css';


// Create the function
// export function AddLibrary(urlOfTheLibrary) {
//   const script = document.createElement('script');
//   script.src = urlOfTheLibrary;
//   script.async = true;
//   document.body.appendChild(script);
// }
//dentro do return
//{AddLibrary('https://unpkg.com/aos@2.3.1/dist/aos.js')}



class App extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
    };
    this.scrolPositon = 0;
  }

  componentDidMount() {


    // AOS.init();


    window.addEventListener('load', () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 100);
      
    });

    var sY = this.scrolPositon;
    //this.scrolPositon = "100007654321";

    //aplicar o SMOTH SCROLL
    var html = document.documentElement;
    var body = document.body;

    var scroller = {
      // to set another target2:document.querySelector("#scroll-container2"),
      target: document.querySelector(".App"),
      ease: 0.2, // <= scroll speed
      endY: 0,
      y: 0,
      resizeRequest: 1,
      scrollRequest: 0,
    };

    var requestId = null;

    // TweenLite.set(scroller.target, {
    //   rotation: 0.001,
    //   force3D: true
    // });

    window.addEventListener("load", onLoad);




 
    //Call the function on scroll
    // window.onscroll = () => {
    //       parallax(parallaxElements);
    // }



    function onLoad() {  
      setTimeout(removeLoader, 1500); 
    }

    function removeLoader() {
      let tapa = document.querySelector(".tapa");
      tapa.classList.add("sair-loader");

      // parallax(parallaxElements);

      setTimeout(function(){ 
        let tapa = document.querySelector(".tapa");
        tapa.style.display = "none";

        //init
        //updateScroller();  
        window.focus();
        //window.addEventListener("resize", onResize);
        // document.addEventListener("scroll", onScroll, {
        //   capture: false,
        //   passive: false
        // }); 




      }, 2000);
    }

    function updateScroller() {
      
      var resized = scroller.resizeRequest > 0;
        
      if (resized) {    
        var height = scroller.target.clientHeight;
        body.style.height = height + "px";
        scroller.resizeRequest = 0;
      }
          
      var scrollY = window.pageYOffset || html.scrollTop || body.scrollTop || 0;

      sY = scrollY;

      scroller.endY = scrollY;
      scroller.y += (scrollY - scroller.y) * scroller.ease;

      if (Math.abs(scrollY - scroller.y) < 0.05 || resized) {
        scroller.y = scrollY;
        scroller.scrollRequest = 0;
      }
      
      TweenLite.set(scroller.target, { 
        y: -scroller.y 
      });
      
      requestId = scroller.scrollRequest > 0 ? requestAnimationFrame(updateScroller) : null;
    }

    let fazResize = true;

    function onScroll() {
      scroller.scrollRequest++;
      if (!requestId) {
        requestId = requestAnimationFrame(updateScroller);
      }
      if (fazResize) {
        // console.log("****** fazResize ?*****");
        onResize();
        fazResize = false;
      }
    
    }

    function onResize() {
      scroller.resizeRequest++;
      if (!requestId) {
        //requestId = requestAnimationFrame(updateScroller);
      }
      setTimeout(function(){ 
        fazResize = true;
       }, 8000);
    }


    //setTimeout(function(){ onResize(); updateScroller(); }, 5000);

    //corect FIREFOX
    //SVGElement.prototype.getTransformToElement = SVGElement.prototype.getTransformToElement || function(toElement) { return toElement.getScreenCTM().inverse().multiply(this.getScreenCTM()); };

  }

  render() {
    // const { loaded, loading } = this.state;
    return (
            <div className="App">



              <HelpNav scrolYPOsi={this.scrolPositon} />
              <Header />
              <Slider />
              <Product />
              <Benefits />
              <Use />
              <Healt />
              <Testimonials />
              <Footer />


            </div>

    );
  }
}


export default App;
